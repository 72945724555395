import useAuthStore from '@core/store/authStore';

export interface Route {
  id?: number;
  name?: string;
  path?: string;
  element?: React.ComponentType | React.ElementType | any;
  functionality?: Route[];
  children?: Route[];
  component?: Route[];
  route_app?: Route[];
  icon?: string | null;
  skipAuth?: boolean;
  value?: any;
  moduleBase?: string;
}

export interface UserRoute {
  isFather?: string;
  path: string;
  id?: number;
  name?: string;
  label?: string;
  icon?: string;
  component_type_id?: number;
  route_app_id?: number;
  functionalityId?: number;
  functionality?: {
    module_id: number;
    children: {
      path: string;
    }[];
  }[];
}

const isRouteMatch = (staticPath: string, dynamicPath: string) => {
  const normalizedDynamicPath = dynamicPath
    .replace(/:\w+/g, '')
    .replace(/\/\*/g, '/')
    .replace(/\/$/, '');

  const normalizedStaticPath = staticPath
    .replace(/:\w+/g, '')
    .replace(/\/\*/g, '/')
    .replace(/\/$/, '');

  return normalizedStaticPath === normalizedDynamicPath;
};

export function routeCheck(
  paths: Route[],
  {
    isAuthenticated,
    userRoutes,
  }: { isAuthenticated: boolean; userRoutes: UserRoute[] },
  permitAll: boolean = false,
) {
  if (permitAll) {
    return paths;
  }
  const getAccessToken = () => useAuthStore.getState().accessToken;
  const accessToken = getAccessToken();

  // Verifica se alguma rota tem a flag skipAuth
  const skipedAuthRoutes = paths
    .filter((route) => route.skipAuth)
    .filter(Boolean) as string[];

  if (!isAuthenticated || !accessToken) {
    return [];
  }

  const extractPaths = (routes: Route[]): string[] => {
    return routes.flatMap(
      (route) =>
        [
          route.path,
          ...(route.functionality ? extractPaths(route.functionality) : []),
          ...(route.children ? extractPaths(route.children) : []),
          ...(route.component ? extractPaths(route.component) : []),
          ...(route.route_app ? [(route.route_app as any).path] : []),
        ].filter(Boolean) as string[],
    );
  };
  const validPaths = extractPaths(userRoutes as Route[]);

  const authorizedPaths = paths.filter((item) => {
    if (item.moduleBase) {
      return userRoutes.some(
        (userRoute) =>
          userRoute.label?.toLowerCase() === item.moduleBase?.toLowerCase(),
      );
    }
    return validPaths.some((validPath) =>
      isRouteMatch(item.path as string, validPath),
    );
  });

  return [...skipedAuthRoutes, ...authorizedPaths];
}
