import { User } from '@ilogix/hooks/useAuth';
import { create } from 'zustand';
import { persist, devtools } from 'zustand/middleware';

export interface mostAccessedFunctionalities {
  id: number;
  functionality_id: number;
  functionality: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string | null;
    icon: string;
  };
  path: string;
  created_at: string;
  updated_at: string | null;
}

interface InitProps {
  isAuthenticated: boolean;
  user: User | null;
  accessToken: string | null;
  routeToken: string | null;
  mostAccessedFunctionalities: mostAccessedFunctionalities[] | [];
}

interface AuthState {
  user: User | null;
  isInitialised: boolean;
  isAuthenticated: boolean;
  accessToken?: string | null;
  routeToken?: string | null;
  mostAccessedFunctionalities: mostAccessedFunctionalities[];
  init: (payload: InitProps) => void;
  login: (user: User, accessToken: string) => void;
  logout: () => void;
  register: (user: User, accessToken: string) => void;
  updateUser: (userData: User) => void;
  updateRoute: (routeToken: string) => void;
  updateToken: (accessToken: string) => void;
}

const environment = import.meta.env.VITE_APP_ENV;

const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        isInitialised: false,
        isAuthenticated: false,
        accessToken: null,
        routeToken: null,
        mostAccessedFunctionalities: [],
        init: ({
          isAuthenticated,
          user,
          accessToken,
          routeToken,
          mostAccessedFunctionalities,
        }) =>
          set(
            {
              isAuthenticated,
              isInitialised: true,
              user,
              accessToken,
              routeToken,
              mostAccessedFunctionalities,
            },
            false,
            'init',
          ),
        updateUser: (userData: User) =>
          set((state) => ({
            ...state,
            user: {
              ...state.user,
              ...userData,
            },
          })),
        updateRoute: (routeToken: string) =>
          set((state) => ({
            ...state,
            routeToken: routeToken,
          })),
        updateToken: (token: string) =>
          set((state) => ({
            ...state,
            accessToken: token,
          })),
        login: (user, accessToken) =>
          set(
            {
              isAuthenticated: true,
              user,
              accessToken,
            },
            false,
            'login',
          ),
        logout: () =>
          set(
            {
              isAuthenticated: false,
              user: null,
              accessToken: null,
              mostAccessedFunctionalities: [],
            },
            false,
            'logout',
          ),
        register: (user, accessToken) =>
          set(
            {
              isAuthenticated: true,
              user,
              accessToken,
            },
            false,
            'register',
          ),
      }),
      {
        name: 'auth', // localStorage
      },
    ),
    {
      store: 'auth', //devtools
      name: 'auth',
      enabled: environment == 'development', //devtools disable production
    },
  ),
);

export default useAuthStore;
