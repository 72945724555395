import Loadable from '@core/components/LayoutTheme/Loadable';
import { useCreateRoutes } from '@ilogix/hooks/useRoute';
import { lazy } from 'react';

const CompanyListPage = Loadable(
  lazy(
    () => import('./views/accessControl/ManageCompany/pages/CompanyListPage'),
  ),
);
const CompanyFormPage = Loadable(
  lazy(
    () => import('./views/accessControl/ManageCompany/pages/CompanyFormPage'),
  ),
);
const UserListPage = Loadable(
  lazy(() => import('./views/accessControl/ManageUser/pages/UserListPage')),
);
const UserFormPage = Loadable(
  lazy(() => import('./views/accessControl/ManageUser/pages/UserFormPage')),
);
const GroupListPage = Loadable(
  lazy(() => import('./views/accessControl/ManageGroup/pages/GroupListPage')),
);
const GroupFormPage = Loadable(
  lazy(() => import('./views/accessControl/ManageGroup/pages/GroupFormPage')),
);
const GroupManagePage = Loadable(
  lazy(() => import('./views/accessControl/ManageGroup/pages/GroupManagePage')),
);
const ProductFormPage = Loadable(
  lazy(() => import('./views/tools/manageProduct/pages/ProductFormPage')),
);
const ProductListPage = Loadable(
  lazy(() => import('./views/tools/manageProduct/pages/ProductListPage')),
);
const UnitFormPage = Loadable(
  lazy(() => import('./views/tools/manageUnit/pages/UnitFormPage')),
);
const UnitListPage = Loadable(
  lazy(() => import('./views/tools/manageUnit/pages/UnitListPage')),
);
const Home = Loadable(lazy(() => import('./views/home/pages/Home')));
const ProfilePage = Loadable(
  lazy(() => import('./views/profile/ManageProfile/pages/ProfilePage')),
);
const LogListPage = Loadable(
  lazy(() => import('./views/accessControl/AccessLog/pages/LogListPage')),
);
const LabelManagePage = Loadable(
  lazy(() => import('./views/accessControl/ManageLabel/pages/LabelManagePage')),
);
const IntegrationDashboardPage = Loadable(
  lazy(
    () =>
      import('./views/dashboard/Integrations/pages/IntegrationDashboardPage'),
  ),
);
const Docs = Loadable(lazy(() => import('./views/docs/AccessDocs/pages/Docs')));

const DevTools = Loadable(lazy(() => import('./views/devTools/Tools/pages/DevTools')));

const PatientFormPage = Loadable(
  lazy(
    () => import('../ilogix/views/tools/managePatient/pages/PatientFormPage'),
  ),
);
const PatientListPage = Loadable(
  lazy(
    () => import('../ilogix/views/tools/managePatient/pages/PatientListPage'),
  ),
);
const Cid10ListPage = Loadable(
  lazy(() => import('../ilogix/views/tools/manageCid10/pages/Cid10ListPage')),
);
const SupplierListPage = Loadable(
  lazy(
    () => import('../ilogix/views/tools/manageSupplier/pages/SupplierListPage'),
  ),
);
const SupplierFormPage = Loadable(
  lazy(
    () => import('../ilogix/views/tools/manageSupplier/pages/SupplierFormPage'),
  ),
);

const ManufacturerFormPage = Loadable(
  lazy(
    () => import('../ilogix/views/tools/manageManufacturers/pages/ManufacturersFormPage')
  ),
);
const ManufacturerListPage = Loadable(
  lazy(
    () => import('../ilogix/views/tools/manageManufacturers/pages/ManufacturersListPage')
  ),
);

export type Route = {
  name: string;
  path: string;
  element: React.ComponentType;
  skipAuth?: boolean;
};

const accessControlRoutes: Route[] = [
  {
    name: 'Gerenciar Empresas',
    path: 'accessControl/manageCompany',
    element: CompanyListPage,
  },
  {
    name: 'Cadastrar Empresa',
    path: 'accessControl/manageCompany/add',
    element: CompanyFormPage,
  },
  {
    name: 'Editar Empresa',
    path: 'accessControl/manageCompany/edit/:id',
    element: CompanyFormPage,
  },
  {
    name: 'Gerenciar Usuários',
    path: 'accessControl/manageUser',
    element: UserListPage,
  },
  {
    name: 'Cadastrar Usuário',
    path: 'accessControl/manageUser/add',
    element: UserFormPage,
  },
  {
    name: 'Editar Usuário',
    path: 'accessControl/manageUser/edit/:id',
    element: UserFormPage,
  },
  {
    name: 'Gerenciar Grupos',
    path: 'accessControl/manageGroup',
    element: GroupListPage,
  },
  {
    name: 'Cadastrar Grupo',
    path: 'accessControl/manageGroup/add',
    element: GroupFormPage,
  },
  {
    name: 'Editar Grupo',
    path: 'accessControl/manageGroup/edit/:id',
    element: GroupFormPage,
  },
  {
    name: 'Gerenciar',
    path: 'accessControl/manageGroup/manage/:id',
    element: GroupManagePage,
  },
  {
    name: 'Log de Acesso',
    path: 'accessControl/accessLog',
    element: LogListPage,
  },
  {
    name: 'Gerenciar URL',
    path: 'accessControl/manageLabel',
    element: LabelManagePage,
  },
  {
    name: 'Acessar Docs',
    path: 'docs/AccessDocs',
    element: Docs,
    skipAuth: true,
  },
  {
    name: 'DevTools',
    path: 'devTools/Tools',
    element: DevTools,
    skipAuth: true,
  },
  {
    name: 'Gerenciar Fabricantes',
    path: 'tools/manageManufacturers',
    element: ManufacturerListPage,
  },
  {
    name: 'Cadastrar Fabricante',
    path: 'tools/manageManufacturers/add',
    element: ManufacturerFormPage,
  },
  {
    name: 'Editar Fabricante',
    path: 'tools/manageManufacturers/edit/:id',
    element: ManufacturerFormPage,
  },
];

const defaultRoutes: Route[] = [
  { name: 'Home', path: 'home', element: Home, skipAuth: true },
];
const dashboardRoutes: Route[] = [
  {
    name: 'Integrações',
    path: 'dashboard/integration',
    element: IntegrationDashboardPage,
  },
];
const profileRoutes: Route[] = [
  {
    name: 'Gerenciar Perfil',
    path: 'profile/manageProfile',
    element: ProfilePage,
  },
];
const toolsRoutes: Route[] = [
  {
    name: 'Gerenciar Produtos',
    path: 'tools/manageProduct',
    element: ProductListPage,
  },
  {
    name: 'Cadastrar Produto',
    path: 'tools/manageProduct/add',
    element: ProductFormPage,
  },
  {
    name: 'Editar Produto',
    path: 'tools/manageProduct/edit/:id',
    element: ProductFormPage,
  },
  {
    name: 'Gerenciar Unidades',
    path: 'tools/manageUnit',
    element: UnitListPage,
  },
  {
    name: 'Cadastrar Unidade',
    path: 'tools/manageUnit/add',
    element: UnitFormPage,
  },
  {
    name: 'Editar Unidade',
    path: 'tools/manageUnit/edit/:id',
    element: UnitFormPage,
  },
  {
    name: 'Gerenciar Pacientes',
    path: 'tools/managePatient',
    element: PatientListPage,
  },
  {
    name: 'Cadastrar Paciente',
    path: 'tools/managePatient/add',
    element: PatientFormPage,
  },
  {
    name: 'Editar Paciente',
    path: 'tools/managePatient/edit/:id',
    element: PatientFormPage,
  },
  {
    name: 'Gerenciar CID-10',
    path: 'tools/manageCid10',
    element: Cid10ListPage,
  },
  {
    name: 'Gerenciar Fornecedores',
    path: 'tools/manageSupplier',
    element: SupplierListPage,
  },
  {
    name: 'Cadastrar Fornecedores',
    path: 'tools/manageSupplier/add',
    element: SupplierFormPage,
  },
  {
    name: 'Editar Fornecedores',
    path: 'tools/manageSupplier/edit/:id',
    element: SupplierFormPage,
  },
];

const useRoutesIlogix = () => {
  const allRoutes = [
    ...accessControlRoutes,
    ...defaultRoutes,
    ...profileRoutes,
    ...dashboardRoutes,
    ...toolsRoutes,
  ];

  return useCreateRoutes(allRoutes as any);
};

export default useRoutesIlogix;
