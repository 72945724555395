import React, { useRef, useState, useEffect } from 'react';
import { Box, styled } from '@mui/material';

const TextWrapper = styled(Box)({
  overflow: 'hidden',
  transition: 'max-width 0.5s ease-in-out',
  maxWidth: '150px',
});

const ItemText = styled('span')(
  ({
    theme,
    translateX,
    shouldTranslate,
  }: {
    theme: any;
    translateX: any;
    shouldTranslate: boolean;
  }) => ({
    fontSize: '0.875rem',
    paddingLeft: '0.8rem',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    transition: 'transform 0.8s ease-in-out',
    '&:hover': {
      textOverflow: shouldTranslate ? 'clip' : 'ellipsis',
      transform: shouldTranslate ? `translateX(-${translateX}%)` : 'none',
    },
  }),
);
const ExpandableText: React.FC<{ text: string; translateX: number }> = ({
  text,
  translateX,
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [fullWidth, setFullWidth] = useState<number>(0);
  const shouldTranslate = text.length > 19;

  useEffect(() => {
    if (textRef.current) {
      setFullWidth(textRef.current.scrollWidth);
    }
  }, [text]);

  return (
    <TextWrapper
      sx={{
        '&:hover': {
          maxWidth: shouldTranslate ? fullWidth : '150px',
        },
      }}
    >
      <ItemText
        ref={textRef}
        translateX={translateX}
        shouldTranslate={shouldTranslate}
      >
        {text}
      </ItemText>
    </TextWrapper>
  );
};
export default ExpandableText;
