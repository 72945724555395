import {
  Box,
  Breadcrumbs,
  Icon,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { NavLink } from 'react-router-dom';

export type RouteSegment = {
  name: string;
  path?: string;
};

export type BreadcrumbProps = {
  routeSegments: RouteSegment[];
  modulePath?: string;
};

// styled components
export const BreadcrumbRoot = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

export const BreadcrumbName = styled('h4')({
  margin: 0,
  fontSize: '16px',
  paddingBottom: '1px',
  verticalAlign: 'middle',
  textTransform: 'capitalize',
});

export const SubName = styled('span')(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

export const Separator = styled('h4')(({ theme }) => ({
  margin: 0,
  marginLeft: 8,
  paddingBottom: '3px',
  color: (theme.palette.text as any).hint,
}));

export const StyledIcon = styled(Icon)({
  marginLeft: 8,
  marginBottom: '4px',
  verticalAlign: 'middle',
  cursor: 'pointer',
  '&:hover': {
    color: '#1976d2',
  },
});

export function Breadcrumb({
  routeSegments,
  modulePath = 'ilogix',
}: BreadcrumbProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hint = (theme.palette.text as any).hint;

  return (
    <BreadcrumbRoot>
      {routeSegments ? (
        <>
          {/* <BreadcrumbName sx={{ fontSize: isMobile ? '14px' : '16px' }}>
            {routeSegments[routeSegments.length - 1]['name']}
          </BreadcrumbName>
          <Separator>|</Separator> */}
        </>
      ) : null}

      <Breadcrumbs
        separator={<Icon sx={{ color: hint }}>navigate_next</Icon>}
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <NavLink to={modulePath !== 'ilogix' ? modulePath : '/home'}>
          <StyledIcon color="disabled">home</StyledIcon>
        </NavLink>

        {routeSegments
          ? routeSegments.map((route, index) => {
              if (index !== routeSegments.length - 1) {
                return route.path ? (
                  <NavLink to={route.path} key={index}>
                    <SubName
                      sx={{
                        fontSize: isMobile ? '12px' : '16px',
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.grey[600]
                            : theme.palette.grey[400],
                      }}
                    >
                      {route.name}
                    </SubName>
                  </NavLink>
                ) : (
                  <SubName
                    sx={{
                      fontSize: isMobile ? '12px' : '16px',
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.grey[600]
                          : theme.palette.grey[400],
                    }}
                    key={index}
                  >
                    {route.name}
                  </SubName>
                );
              } else {
                return (
                  <SubName
                    sx={{
                      fontSize: isMobile ? '12px' : '16px',
                      fontWeight: 600,
                    }}
                    key={index}
                  >
                    {route.name}
                  </SubName>
                );
              }
            })
          : null}
      </Breadcrumbs>
    </BreadcrumbRoot>
  );
}

export default Breadcrumb;
