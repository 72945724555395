import React from 'react';

import { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonBase, Icon, Box, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import { Expand } from '@mui/icons-material';
import ExpandableText from './ExpandableText';

const NavExpandRoot = styled('div')(({ theme }) => ({
  '& .expandIcon': {
    transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
    transform: 'rotate(0deg)',
  },
  '& .collapseIcon': {
    transition: 'transform 0.3s cubic-bezier(0, 0, 0.2, 1) 0ms',
    transform: 'rotate(90deg)',
  },
  '& .expansion-panel': {
    overflow: 'hidden',
    transition: 'none',
  },
  '& .highlight': {
    background: theme.palette.primary.main,
  },
  '&.compactNavItem': {
    width: 44,
    overflow: 'hidden',
    justifyContent: 'center !important',
    '& .itemText': { display: 'none' },
    '& .itemIcon': { display: 'none' },
  },
}));

const BaseButton = styled(ButtonBase)(({ theme }) => ({
  height: 44,
  width: '100%',
  whiteSpace: 'pre',
  overflow: 'hidden',
  paddingRight: '16px',
  borderRadius: '4px',
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'space-between !important',
  color: theme.palette.text.primary,
  '&:hover': { background: theme.palette.action.hover },
  '& .icon': {
    width: 36,
    fontSize: '25px',
    paddingLeft: '11px',
    paddingRight: '25px',
    verticalAlign: 'middle',
  },
}));

const BulletIcon = styled('div')(({ theme }) => ({
  width: 4,
  height: 4,
  color: 'inherit',
  overflow: 'hidden',
  marginLeft: '20px',
  marginRight: '8px',
  borderRadius: '300px !important',
  background: theme.palette.text.primary,
}));

const ButtonContent = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const ChevronContainer = styled('div')<{ mode?: string }>(({ mode }) => ({
  position: 'absolute',
  right: 0,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  pointerEvents: 'none',
  visibility: mode === 'compact' ? 'hidden' : 'visible',
}));

type AlphaVerticalNavExpansionPanelProps = {
  item: {
    name: string;
    icon?: React.ReactNode;
    iconText?: React.ReactNode;
    badge?: {
      value: React.ReactNode;
    };
  };
  children: React.ReactNode;
  mode: string;
};

const AlphaVerticalNavExpansionPanel: React.FC<
  AlphaVerticalNavExpansionPanelProps
> = ({ item, children, mode }) => {
  const [collapsed, setCollapsed] = useState(true);
  const elementRef = useRef<HTMLDivElement>(null);
  const componentHeight = useRef(0);
  const { pathname } = useLocation();
  const { name, icon, iconText, badge } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    componentHeight.current = 0;
    calcaulateHeight(elementRef.current);
    setCollapsed(!collapsed);
    setOpen(!open);
  };

  const calcaulateHeight = useCallback((node: any) => {
    if (node.name !== 'child') {
      for (let child of node.children) {
        calcaulateHeight(child);
      }
    }

    if (node.name === 'child') componentHeight.current += node.scrollHeight;
    else componentHeight.current += 44; //here 44 is node height
    return;
  }, []);

  useEffect(() => {
    if (!elementRef) return;

    calcaulateHeight(elementRef.current);

    for (let child of elementRef.current?.querySelectorAll('a') || []) {
      if (child.getAttribute('href') === location.pathname) {
        setCollapsed(false);
        break;
      }
    }
  }, [pathname, calcaulateHeight]);

  return (
    <NavExpandRoot>
      <BaseButton
        className={clsx({
          'has-submenu compactNavItem': true,
          compactNavItem: mode === 'compact',
          open: !collapsed,
        })}
        onClick={handleClick}
      >
        <ButtonContent>
          {icon && <Icon className="icon">{icon}</Icon>}
          {iconText && <BulletIcon />}
          <ExpandableText text={name} translateX={40} />
          <ChevronContainer mode={mode} className="sidenavHoverShow">
            <ChevronRightIcon
              className={clsx({
                itemIcon: true,
                expandIcon: collapsed,
                collapseIcon: !collapsed,
              })}
            />
          </ChevronContainer>
        </ButtonContent>
      </BaseButton>

      <div
        ref={elementRef}
        className="expansion-panel submenu"
        style={
          collapsed
            ? { maxHeight: '0px' }
            : { maxHeight: componentHeight.current + 'px' }
        }
      >
        {children}
      </div>
    </NavExpandRoot>
  );
};

export default AlphaVerticalNavExpansionPanel;
