import React from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import useAuthStore from '@core/store/authStore';

function AuthGuard({ children }: { children: React.ReactNode }) {
  const { isAuthenticated } = useAuthStore();
  const location = useLocation();

  const hasParameters = /\d/.test(location.pathname);

  if (isAuthenticated) return <>{children}</>;

  return (
    <Navigate
      replace
      to="/session/signin"
      state={{ from: hasParameters ? '/home' : location.pathname }}
    />
  );
}

export default AuthGuard;
