import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import StarPurple500RoundedIcon from '@mui/icons-material/StarPurple500Rounded';
import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import useUserPreferencesStore from '@core/store/userPreferencesStore';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface FavoriteRouteCheckBoxProps {
  item: {
    name: string;
    path: string;
    icon: string;
  };
  mode: string;
  style?: any;
}

const excludeRoutes = ['/home', '/'];

const FavoriteRouteCheckBox = ({
  item,
  mode,
  style,
}: FavoriteRouteCheckBoxProps) => {
  const theme = useTheme();
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  const { name, path, icon } = item;
  const { favoritesRoutes, setFavoritesRoutes } = useUserPreferencesStore();

  useEffect(() => {
    setChecked(favoritesRoutes.some((route) => route.path === path));
  }, [favoritesRoutes]);

  const handleChange = () => {
    const isCurrentlyFavorite = favoritesRoutes.some(
      (route) => route.path === path,
    );

    setChecked(!isCurrentlyFavorite);

    setFavoritesRoutes(
      isCurrentlyFavorite
        ? favoritesRoutes.filter((route) => route.path !== path)
        : [...favoritesRoutes, { name, path, icon }],
    );
  };

  return location.pathname === path &&
    !excludeRoutes.includes(location.pathname) ? (
    <Checkbox
      className="sidebar-star-checkbox"
      checked={checked}
      onClick={handleChange}
      sx={{ display: mode === 'compact' ? 'none': 'block', ...style }}
      icon={
        <StarPurple500RoundedIcon
          fontSize="small"
          sx={{
            color:
              theme.palette.mode === 'light'
                ? 'rgba(52, 49, 76, 0.26)'
                : '#ffffff2b',
          }}
        />
      }
      checkedIcon={<StarRateRoundedIcon fontSize="small" color="secondary" />}
    />
  ) : null;
};

export default FavoriteRouteCheckBox;
