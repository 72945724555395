import { useCreateRoutes } from '@ilogix/hooks/useRoute';
import Loadable from '@core/components/LayoutTheme/Loadable';
import { lazy } from 'react';

const WarehouseListPage = Loadable(
  lazy(() => import('./views/tools/manageWarehouse/pages/WarehouseListPage')),
);
const WarehouseFormPage = Loadable(
  lazy(() => import('./views/tools/manageWarehouse/pages/WarehouseFormPage')),
);
const Home = Loadable(lazy(() => import('./views/home/pages/Home')));
const LocationFormPage = Loadable(
  lazy(() => import('./views/tools/manageLocation/pages/LocationFormPage')),
);
const LocationListPage = Loadable(
  lazy(() => import('./views/tools/manageLocation/pages/LocationListPage')),
);
const SectorFormPage = Loadable(
  lazy(() => import('./views/tools/manageSector/pages/SectorFormPage')),
);
const SectorListPage = Loadable(
  lazy(() => import('./views/tools/manageSector/pages/SectorListPage')),
);
const BalanceListPage = Loadable(
  lazy(() => import('./views/products/balance/pages/BalanceListPage')),
);
const MonthlyUsageListPage = Loadable(
  lazy(
    () => import('./views/products/monthlyUsage/pages/MonthlyUsageListPage'),
  ),
);
const TransactionListPage = Loadable(
  lazy(
    () =>
      import('./views/transaction/transactionList/pages/TransactionListPage'),
  ),
);
const OutFormPage = Loadable(
  lazy(() => import('./views/transaction/out/outRegister/pages/OutFormPage')),
);
const OutListPage = Loadable(
  lazy(() => import('./views/transaction/out/outList/pages/OutListPage')),
);
const EntryFormPage = Loadable(
  lazy(
    () => import('./views/transaction/entry/entryRegister/pages/EntryFormPage'),
  ),
);
const EntryListPage = Loadable(
  lazy(() => import('./views/transaction/entry/entryList/pages/EntryListPage')),
);
const TransferWarehouseFormPage = Loadable(
  lazy(
    () =>
      import(
        './views/transaction/transfer/transferWarehouse/pages/TransferWarehouseFormPage'
      ),
  ),
);
const TransferWarehouseListPage = Loadable(
  lazy(
    () =>
      import(
        './views/transaction/transfer/transferWarehouse/pages/TransferWarehouseListPage'
      ),
  ),
);
const TransferLocationFormPage = Loadable(
  lazy(
    () =>
      import(
        './views/transaction/transfer/transferLocation/pages/TransferLocationFormPage'
      ),
  ),
);
const TransferLocationListPage = Loadable(
  lazy(
    () =>
      import(
        './views/transaction/transfer/transferLocation/pages/TransferLocationListPage'
      ),
  ),
);
const RestockListPage = Loadable(
  lazy(
    () =>
      import('./views/transaction/restock/restockList/pages/RestockListPage'),
  ),
);
const RestockFormPage = Loadable(
  lazy(
    () =>
      import(
        './views/transaction/restock/restockRegister/pages/RestockFormPage'
      ),
  ),
);
const OperationFormPage = Loadable(
  lazy(() => import('./views/tools/manageOperation/pages/OperationFormPage')),
);
const OperationListPage = Loadable(
  lazy(() => import('./views/tools/manageOperation/pages/OperationListPage')),
);
const MonthlyClosingListPage = Loadable(
  lazy(
    () => import('./views/tools/monthlyClosing/pages/MonthlyClosingListPage'),
  ),
);
const UsageHistoricListPage = Loadable(
  lazy(
    () => import('./views/products/usageHistoric/pages/UsageHistoricListPage'),
  ),
);
const InvoiceReversalListPage = Loadable(
  lazy(
    () =>
      import(
        '@warehouse/views/transaction/invoiceReversal/invoiceReversalList/pages/InvoiceReversalListPage'
      ),
  ),
);
const InvoiceReversalFormPage = Loadable(
  lazy(
    () =>
      import(
        '@warehouse/views/transaction/invoiceReversal/invoiceReversalRegister/pages/InvoiceReversalFormPage'
      ),
  ),
);
const RequestListPage = Loadable(
  lazy(() => import('@warehouse/views/request/pages/RequestListPage')),
);
const RequestFormPage = Loadable(
  lazy(() => import('@warehouse/views/request/pages/RequestFormPage')),
);
const OrderListPage = Loadable(
  lazy(() => import('@warehouse/views/order/pages/OrderListPage')),
);
const OrderFormPage = Loadable(
  lazy(() => import('@warehouse/views/order/pages/OrderFormPage')),
);

export type Route = {
  name: string;
  path: string;
  element: React.ComponentType;
  skipAuth?: boolean;
  moduleBase?: string;
};

const defaultRoutes: Route[] = [
  { name: 'Estoque', path: '', element: Home, moduleBase: 'warehouse' },
];

const toolsRoutes: Route[] = [
  {
    name: 'Gerenciar Estoques',
    path: 'warehouse/tools/manageWarehouse',
    element: WarehouseListPage,
  },
  {
    name: 'Cadastrar Estoques',
    path: 'warehouse/tools/manageWarehouse/add',
    element: WarehouseFormPage,
  },
  {
    name: 'Editar Estoques',
    path: 'warehouse/tools/manageWarehouse/edit/:id',
    element: WarehouseFormPage,
  },
  {
    name: 'Gerenciar Setores',
    path: 'warehouse/tools/manageSector',
    element: SectorListPage,
  },
  {
    name: 'Cadastrar Setores',
    path: 'warehouse/tools/manageSector/add',
    element: SectorFormPage,
  },
  {
    name: 'Editar Setores',
    path: 'warehouse/tools/manageSector/edit/:id',
    element: SectorFormPage,
  },
  {
    name: 'Gerenciar Localizações',
    path: 'warehouse/tools/manageLocation',
    element: LocationListPage,
  },
  {
    name: 'Cadastrar Localizações',
    path: 'warehouse/tools/manageLocation/add',
    element: LocationFormPage,
  },
  {
    name: 'Editar Localizações',
    path: 'warehouse/tools/manageLocation/edit/:id',
    element: LocationFormPage,
  },
  {
    name: 'Gerenciar Operações',
    path: 'warehouse/tools/manageOperation',
    element: OperationListPage,
  },
  {
    name: 'Cadastrar Operações',
    path: 'warehouse/tools/manageOperation/add',
    element: OperationFormPage,
  },
  {
    name: 'Editar Operações',
    path: 'warehouse/tools/manageOperation/edit/:id',
    element: OperationFormPage,
  },
  {
    name: 'Fechamento Mensal',
    path: 'warehouse/tools/monthlyClosing',
    element: MonthlyClosingListPage,
  },
];

const productsRoutes: Route[] = [
  {
    name: 'Lista - Saldo',
    path: 'warehouse/products/balance',
    element: BalanceListPage,
  },
  {
    name: 'Lista - Consumo Mensal',
    path: 'warehouse/products/monthlyUsage',
    element: MonthlyUsageListPage,
  },
  {
    name: 'Lista - Histórico de Consumo',
    path: 'warehouse/products/usageHistoric',
    element: UsageHistoricListPage,
  },
];

const transactionRoutes: Route[] = [
  {
    name: 'Listar',
    path: 'warehouse/transaction/transactionList',
    element: TransactionListPage,
  },
  {
    name: 'Entrada - Lista',
    path: 'warehouse/transaction/entry/list',
    element: EntryListPage,
  },
  {
    name: 'Entrada - Cadastro',
    path: 'warehouse/transaction/entry/add',
    element: EntryFormPage,
  },
  {
    name: 'Saída - Lista',
    path: 'warehouse/transaction/out/list',
    element: OutListPage,
  },
  {
    name: 'Saída - Cadastro',
    path: 'warehouse/transaction/out/add',
    element: OutFormPage,
  },
  {
    name: 'Transferência Estoque - Lista',
    path: 'warehouse/transaction/transferWarehouse',
    element: TransferWarehouseListPage,
  },
  {
    name: 'Transferência Estoque - Cadastro',
    path: 'warehouse/transaction/transferWarehouse/add',
    element: TransferWarehouseFormPage,
  },
  {
    name: 'Transferência Localização - Lista',
    path: 'warehouse/transaction/transferLocation',
    element: TransferLocationListPage,
  },
  {
    name: 'Transferência Localização - Cadastro',
    path: 'warehouse/transaction/transferLocation/add',
    element: TransferLocationFormPage,
  },
  {
    name: 'Devolução - Lista',
    path: 'warehouse/transaction/restock/list',
    element: RestockListPage,
  },
  {
    name: 'Devolução - Cadastro',
    path: 'warehouse/transaction/restock/add',
    element: RestockFormPage,
  },
  {
    name: 'Estorno Entrada - Lista',
    path: 'warehouse/transaction/invoiceReversal/list',
    element: InvoiceReversalListPage,
  },
  {
    name: 'Estorno Entrada - Cadastro',
    path: 'warehouse/transaction/invoiceReversal/add',
    element: InvoiceReversalFormPage,
  },
];

const requestRoutes: Route[] = [
  {
    name: 'Requisição - Lista',
    path: 'warehouse/request/list',
    element: RequestListPage,
  },
  {
    name: 'Requisição - Cadastro',
    path: 'warehouse/request/add',
    element: RequestFormPage,
  },
];

const orderRoutes: Route[] = [
  {
    name: 'Pedido - Lista',
    path: 'warehouse/order/list',
    element: OrderListPage,
  },
  {
    name: 'Pedido - Cadastro',
    path: 'warehouse/order/add',
    element: OrderFormPage,
  },
  {
    name: 'Pedido - Editar',
    path: 'warehouse/order/edit/:id',
    element: OrderFormPage,
  },
];

const useRoutesWarehouse = () => {
  const allRoutes = [
    ...toolsRoutes,
    ...defaultRoutes,
    ...productsRoutes,
    ...transactionRoutes,
    ...requestRoutes,
    ...orderRoutes,
  ];

  return useCreateRoutes(allRoutes as any);
};

export default useRoutesWarehouse;
