import { api } from '@core/data/api';
import useAuthStore from '@core/store/authStore';
import { encrypt } from '@core/utils/cryptoUtils';

export const fetchUserRoutes = async (): Promise<any> => {
  const { logout, updateRoute } = useAuthStore.getState();

  try {
    const response = await api.get('/ilogix/access-control/routes');
    const routesData = response.data.response;

    updateRoute(routesData);

    //const encryptedRoutes = encrypt(JSON.stringify(routesData));

    //localStorage.setItem('routes', routesData || '');

    return routesData;
  } catch (error) {
    logout();
    throw error;
  }
};

export const generateRoutesByGroup = async (
  groupId: number,
): Promise<string> => {
  const response = await api.post(
    '/ilogix/access-control/generate-routes-by-group',
    {
      group_id: groupId,
    },
  );

  return response.data.response;
};

export const generateTokenByGroup = async (
  groupId: number,
): Promise<string> => {
  const response = await api.post('/ilogix/auth/generate-token-by-group', {
    group_id: groupId,
  });

  return response.data.response;
};
